import maplibregl from "maplibre-gl";
import api from "@/util/api";

const tileServerUrl = "https://covertnest-tileserver.up.railway.app";
export const localAreaTilesUrl = `${tileServerUrl}/data/usa-1p-z14`;
export const localAreaLayerSource = "usa";

export const osmStyleConfig = {
  version: 8,
  sources: {
    osm: {
      type: "raster",
      tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
      tileSize: 256,
    },
  },
  layers: [
    {
      id: "osm",
      type: "raster",
      source: "osm",
      minzoom: 0,
      maxzoom: 19,
    },
  ],
};

export function addLocalAreasToMap(map) {
  map.addSource("local-areas", {
    type: "vector",
    tiles: [`${localAreaTilesUrl}/{z}/{x}/{y}.pbf`],
    promoteId: "ZCTA5CE10",
  });

  map.addLayer({
    id: "local-areas-fill",
    type: "fill",
    source: "local-areas",
    "source-layer": localAreaLayerSource, // Source layer name
    paint: {
      "fill-color": "#000", // Any color, but it'll be invisible due to opacity
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false], // If hover is true
        0.5, // Opacity when hovered
        0.3, // Default opacity
      ],
    },
  });

  map.addLayer({
    id: "local-areas-boundary",
    type: "line",
    source: "local-areas",
    "source-layer": localAreaLayerSource, // Source layer name
    paint: {
      "line-color": "#000", // Red color for boundaries
      "line-width": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        2,
        0.3,
      ], // Thickness of the boundary line
    },
  });
}

export const getVisibleZipCodes = (map) => {
  const bounds = map.getBounds();
  const features = map.queryRenderedFeatures(bounds, {
    layers: ["local-areas-fill"], // Replace 'zipcode-layer' with the actual layer ID that contains your zip code data
  });
  const zipCodes = features.map((feature) => feature.properties["ZCTA5CE10"]); // Adjust 'zipCode' based on how the property is named in your data
  // console.log("Visible Zip Codes:", zipCodes);
  return zipCodes;
};

// deprecated
export function handleMapEvents(map) {
  // Variables for event handlers
  let hoveredLocalAreaId = null;
  let popup = null;

  map.on("mousemove", "local-areas-fill", (e) => {
    let feature;
    if (e.features.length > 0) {
      feature = e.features[0];

      if (hoveredLocalAreaId !== null) {
        map.setFeatureState(
          {
            sourceLayer: localAreaLayerSource,
            source: "local-areas",
            id: hoveredLocalAreaId,
          },
          { hover: false }
        );
      }

      hoveredLocalAreaId = feature.id;
      map.setFeatureState(
        {
          sourceLayer: localAreaLayerSource,
          source: "local-areas",
          id: feature.properties["ZCTA5CE10"],
        },
        { hover: true }
      );
    }

    // If there's an existing popup, remove it
    if (popup) {
      popup.remove();
    }

    // Create a new popup showing the zipcode (or any other feature property)
    popup = new maplibregl.Popup({ closeButton: false, offset: [0, -15] })
      .setLngLat(e.lngLat)
      .setHTML(`<p>Zip Code: ${feature?.properties["ZCTA5CE10"]}</p>`)
      .addTo(map);
  });

  map.on("mouseleave", "local-areas-fill", (e) => {
    if (hoveredLocalAreaId !== null) {
      map.setFeatureState(
        {
          sourceLayer: localAreaLayerSource,
          source: "local-areas",
          id: hoveredLocalAreaId,
        },
        { hover: false }
      );
    }

    // Remove popup
    if (popup) {
      popup.remove();
      popup = null;
    }

    hoveredLocalAreaId = null;
  });
}

/**
 *
 * @param {string[]} zipcodes @description An array of zipcodes
 * @param {{ dataPoint: string, year: number, label: string }} dataFilter  @description An object containing dataPoint, year, and label
 * @returns
 */
export async function getCensusData(zipcodes, dataFilter) {
  /*
    const censusData = await ky
    .post("/api/census-data", {
      json: {
        zipcodes,
        dataFilter,
      },
      
    })
    .json();
    */

  const censusData = await api.post("/data/get-census-data", {
    zipcodes,
    dataFilter,
  });

  
  return censusData.data;
}

export async function getDataPointsMinMax() {
  const dataPointsMinMax = await api.get("/data/get-census-data-min-max");
  return dataPointsMinMax.data;
}

export async function fetchZipcodesList() {
    const zipcodes = await api.get("/data/zipcodes?include_location=true");
    return zipcodes.data;
};

export async function getMatchingZipcodes(zipcode, zipcodesList) {
    // Filter the zipcodesList to only include zipcodes that match the zipcode
    const matchingZipcodes = zipcodesList.filter((zipcode) => zipcode.startsWith(zipcode));

    // Return only top 5 matching zipcodes
    return matchingZipcodes.slice(0, 5);
}