import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import api from "@/util/api";
import { Search, LineChartIcon, ChevronUp, ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { twMerge } from "tailwind-merge";
import Spinner from "../Common/Spinner";

/*
const chartData = [
  { year: "2019", value: 65000 },
  { year: "2020", value: 67000 },
  { year: "2021", value: 70000 },
  { year: "2022", value: 72000 },
  { year: "2023", value: 75000 },
];
*/

async function getZipcodeData(zipcode, datapoint) {
  try {
    const url = `/data/zipcode/${zipcode}${
      datapoint ? `?category=${datapoint}` : ""
    }`;
    const res = await api.get(url);
    const data = res.data;
    return data || [];
  } catch (err) {
    const errMessage = err.response?.data?.message || err.message;
    console.log("[getZipcodeData] Error:", errMessage);
    return [];
  }
}

export default function DataLineChart({ className, zipcode, datapoint }) {
  const [zipcodeData, setZipcodeData] = useState(null);

  // TODO Finish Loading the data; Handle Edgecases
  useEffect(() => {
    (async () => {
      const data = await getZipcodeData(zipcode, datapoint);
      setZipcodeData(data);
    })();
  }, [datapoint, zipcode]);

  if (zipcodeData === null || zipcodeData === undefined) {
    // Loader
    return (
      <div className="w-full flex flex-col items-center justify-center gap-1">
        <Spinner />
        {/* <p className="text-xs">Loading...</p> */}
      </div>
    );
  }

  if (zipcodeData.length === 0) {
    return (
      <div className="w-full flex flex-col items-center justify-center gap-1">
        <p className="">No Data Available</p>
      </div>
    );
  }

  return (
    <ChartContainer
      config={{
        value: {
          label: "Value",
          color: "hsl(var(--chart-2))",
        },
      }}
    >
      <div className={twMerge("w-full h-full", className)}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={zipcodeData}
            margin={{
              top: 20,
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="year"
              tickLine={false}
              axisLine={false}
              // Add Padding so that the y-axis labels don't overlap with the line
              padding={{
                right: 20,
                left: 20,
              }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value.toLocaleString()}`}
              padding={{
                top: 20,
                bottom: 20,
              }}
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            <Line
              type="monotone"
              dataKey="value"
              stroke="var(--color-value)"
              strokeWidth={2}
              dot={true}
            >
              <LabelList
                position="bottom"
                offset={12}
                className="fill-gray-500"
                fontSize={12}
              />
            </Line>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </ChartContainer>
  );
}
