import { MapPinIcon, SearchIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import { cn, debounce } from "@/lib/utils";
import { useState, useCallback } from "react";
import Spinner from "@/components/Common/Spinner";

const items = [
  "10001",
  "10002",
  "10003",
  "10004",
  "10005",
  "10006",
  "10007",
  "10008",
];

export default function AutoCompleteInput({
  value,
  onValueChange,
  isLoading,
  className,
  getSuggestions,
  onSuggestionSelected,
  debounceDelay = 500, // Delay in milliseconds
  ...props
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsBoxOpen, setIsSuggestionsBoxOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0); // Index of the highlighted suggestion

  // Debounced function for fetching suggestions
  const fetchSuggestions = useCallback(
    debounce(async (val) => {
      if (val) {
        const suggests = await getSuggestions?.(val);
        setSuggestions(suggests);
        setIsSuggestionsBoxOpen(true);
      } else {
        setSuggestions([]);
        setIsSuggestionsBoxOpen(false);
        setHighlightedIndex(0);
      }
    }, debounceDelay),
    [value] // Important, Recreates the debounced function when the value changes, making sure the suggestions are updated
  );

  const getHighlightedText = (text, highlight) => {
    if (!highlight || !text) return text;

    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="font-semibold">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  function handleInputChange(e) {
    const val = e.target.value;
    onValueChange?.(val);
    fetchSuggestions(val);
  }

  function handleSuggestionSelected(selectedSuggestion) {
    // const selectedSuggestion = suggestions[highlightedIndex];
    onValueChange?.(selectedSuggestion);
    onSuggestionSelected?.(selectedSuggestion);

    // Reset the suggestions
    setIsSuggestionsBoxOpen(false);
    setHighlightedIndex(0);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (suggestions.length > 0) {
        handleSuggestionSelected(suggestions[highlightedIndex]);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent default behavior on Arrow down
      // Navigate down the suggestions
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent default behavior on Arrow up
      // Navigate up the suggestions
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
      );
    }
  };

  return (
    <div className="gap-1 relative">
      <div className="relative w-full">
        <Input
          {...props}
          type="number"
          className={cn("w-full pl-10 pr-4 bg-white", className)}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          // onBlur={() => setIsSuggestionsBoxOpen(false)}
        />
        <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
      </div>
      {isSuggestionsBoxOpen && (
        <div className="bg-white rounded border border-gray-300 overflow-hidden absolute w-full top-10">
          {suggestions?.length > 0 ? (
            suggestions?.map((item, idx) => (
              <div
                key={idx}
                className={cn(
                  "px-3 py-3 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ease-in-out border-b border-gray-100 last:border-b-0 flex items-center gap-3 text-muted-foreground text-sm",
                  { "bg-gray-100": highlightedIndex === idx }
                )}
                onMouseEnter={() => setHighlightedIndex(idx)} // Highlight on mouse hover
                onClick={() => {
                  onValueChange?.(item); // Set the clicked suggestion as the value
                  handleSuggestionSelected(suggestions[highlightedIndex]);
                }}
              >
                <MapPinIcon
                  className="w-5 h-5 text-muted-foreground"
                  strokeWidth={1.5}
                />
                <div className="space-y-0">
                  {getHighlightedText(item, value)}
                </div>
              </div>
            ))
          ) : isLoading ? (
            <div className="flex items-center justify-center gap-1 text-sm p-4">
              <Spinner className={"w-5 h-5"} />
            </div>
          ) : (
            <p className="text-sm text-muted-foreground p-4 text-center">
              No suggestions found
            </p>
          )}
        </div>
      )}
    </div>
  );
}
