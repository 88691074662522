import { useContext } from "react";
import DataMap from "./DataMap";
import { UserContext } from "@/context/UserContext";
import UpgradeCard from "@/components/UpgradeCard";

export default function DataMapPage() {
  const { user } = useContext(UserContext);

  if (!user?.isSubscribed) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center">
        <UpgradeCard isLoggedIn={!!user} />
      </div>
    );
  }

  return <DataMap />;
}
