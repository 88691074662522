import React, { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Switch from "@mui/material/Switch";

import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";

import Logo from "../../components/Home/Logo";

import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import login from "../../images/upgrade.webp";
import { Divider } from "@mui/material";
import api from "../../util/api";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const theme = createTheme();

export default function Subscription() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [upgrade, setUpgrade] = React.useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = React.useState(null);
  const [isSubRenewing, setIsSubRenewing] = React.useState(false);

  const fetchPageData = async () => {
    try {
      const { data } = await api.get("/pages/upgrade");

      setUpgrade(data);

      console.log("Upgrade Data: ", data);
      console.log("user: ", user);
    } catch (error) {
      console.error("Failed to Get the Home Data:", error.message);
    }
  };

  React.useEffect(() => {
    fetchPageData();
    console.log("User: ", user);
    if (user && user.isSubscribed) {
      fetchUserPaymentDetails()
        .then((data) => {
          if (data) {
            setSubscriptionDetails(data?.subscription);
            setIsSubRenewing(
              data?.subscription?.cancel_at_period_end === true ? false : true
            );
          }
        })
        .catch((err) => {
          toast.error("Error fetching user payment details: " + err.message);
        });
    }
  }, []);

  const handleClick = async () => {
    if (!user) {
      navigate("/signup");
    } else {
      try {
        const { data } = await api.post("/payment/process", {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        });
        window.location.href = data.url; // Redirects to Stripe Checkout
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error.message;
        toast.error("Error Initiating Payment: " + errorMessage);
      }
    }
  };

  async function handleToggleSubRenew(event) {
    const boolValue = event.target.checked;
    setIsSubRenewing(boolValue);

    const toastId = toast.loading("Updating Subscription...");
    try {
      const { data } = await api.put("/user/updateSubscriptionRenewal", {
        enableAutoRenew: boolValue,
      });
      toast.update(toastId, {
        render: data.message,
        type: "success",
        isLoading: false,
      });
      setIsSubRenewing(boolValue);
    } catch (error) {
      const errMessage = error?.response?.data?.message || error.message;
      toast.update(toastId, {
        render: "Error updating subscription: " + errMessage,
        type: "error",
        isLoading: false,
      });
      setIsSubRenewing(!boolValue);
    }
  }

  const handleCancel = async () => {
    try {
      const { data } = await api.delete("/payment/cancel-subscription"); // DELETE request
      // toast(data.message || "Subscription canceled successfully!");
    } catch (error) {
      console.error("Error canceling subscription:", error.message);
      // toast(
      //   error?.response?.data?.message ||
      //     "Failed to cancel subscription. Please try again."
      // );
    }
  };

  return (
    <div className=" max-w-[1440px] mx-auto lg:max-h-[800px]">
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <div className=" w-[150px] ml-1 mt-7 hidden md:block">
              <Logo />
            </div>
            <Box
              className="w-[80%] max-w-[500px]"
              sx={{
                mt: 4,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
              <Box sx={{ alignSelf: "start" }}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    fontSize: { xs: 18, md: 25 },
                    marginBottom: "5px",
                    maxWidth: "400px",
                  }}
                >
                  {user?.isSubscribed
                    ? "Your Subscription Details"
                    : upgrade?.title}
                </Typography>
                <Divider
                  sx={{
                    width: "20%",
                    height: "3px",
                    bgcolor: "#3296ff",
                    mb: 3,
                  }}
                />
              </Box>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {user?.isSubscribed ? (
                  subscriptionDetails ? (
                    <>
                      {/* Display payment details */}
                      <Typography variant="h6" gutterBottom>
                        Subscription Details
                      </Typography>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: "blue",
                              bgcolor: "rgba(113, 110, 220, 0.19)",
                            }}
                          >
                            <AssignmentTurnedInOutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          // primary={`Total Amount: $${user?.hasSubscription.status.payment.amount_total}`}
                          primary={`Total Amount: $${(
                            subscriptionDetails.plan.amount / 100
                          ).toFixed(2)}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: "blue",
                              bgcolor: "rgba(113, 110, 220, 0.19)",
                            }}
                          >
                            <AssignmentTurnedInOutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`Start Date: ${new Date(
                            subscriptionDetails.current_period_start * 1000
                          ).toLocaleDateString()}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: "blue",
                              bgcolor: "rgba(113, 110, 220, 0.19)",
                            }}
                          >
                            <AssignmentTurnedInOutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`Expiry Date: ${new Date(
                            subscriptionDetails.current_period_end * 1000
                          ).toLocaleDateString()}`}
                        />
                      </ListItem>
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          padding: "16px",
                          maxWidth: "400px",
                          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                          backgroundColor: "#f9f9f9",
                          marginTop: "20px",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            // marginBottom: "8px",
                            color: "#333",
                          }}
                        >
                          Auto-Renewal
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" sx={{ color: "#555" }}>
                            {isSubRenewing
                              ? `Subscription will renew on ${new Date(
                                  subscriptionDetails.current_period_end * 1000
                                ).toLocaleDateString()}`
                              : `Subscription will expire on ${new Date(
                                  subscriptionDetails.current_period_end * 1000
                                ).toLocaleDateString()}`}
                          </Typography>
                          <Switch
                            checked={isSubRenewing}
                            onChange={handleToggleSubRenew}
                            color="primary"
                            inputProps={{ "aria-label": "Subscription toggle" }}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Typography variant="h6" gutterBottom>
                      Loading Subscription Details...
                    </Typography>
                  )
                ) : (
                  <>
                    {/* Display upgrade benefits if no active subscription */}
                    <Typography variant="h6" gutterBottom>
                      Subscription Benefits
                    </Typography>
                    {upgrade?.benefits.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: "blue",
                              bgcolor: "rgba(113, 110, 220, 0.19)",
                            }}
                          >
                            <AssignmentTurnedInOutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </>
                )}
              </List>
              {!user?.isSubscribed && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={user ? handleClick : () => navigate("/login")}
                  sx={{
                    mt: 3,
                    mb: 2,
                    p: 1,
                    bgcolor: "#716EDC",
                    "&:hover": {
                      backgroundColor: "#716EDC",
                    },
                  }}
                >
                  {user ? "Subscribe Now" : "Login to Subscribe"}
                </Button>
              )}
            </Box>
          </Grid>
          <Grid
            xs={false}
            sm={4}
            md={6}
            mt={2}
            pr={3}
            sx={{
              // backgroundImage: "url(https://source.unsplash.com/random)",
              backgroundImage: `url(${login})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
            borderRadius={4}
            item
          >
            <Box
              sx={{
                width: "300px",
                height: "280px",
                bgcolor: "rgba(255, 255, 255, 0.31)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center ",
                flexDirection: "column",
                gap: "10px",
                fontSize: "40px",
              }}
            >
              {["PREMIUM", "$49", "Billed Anually"].map((item, index) => (
                <Typography
                  key={index}
                  sx={{ fontSize: "20px", fontWeight: "bold", color: "white" }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

async function fetchUserPaymentDetails() {
  const response = await api.get("/user/paymentDetails");
  console.log("User Subscription Details: ", response.data);
  return response.data.data;
}
