// MapComponent.js
import React, { useEffect, useRef } from "react";
import "maplibre-gl/dist/maplibre-gl.css"; // Import MapLibre CSS
import maplibregl from "maplibre-gl"; // Import MapLibre library
import {
  addLocalAreasToMap,
  osmStyleConfig,
  getVisibleZipCodes,
  localAreaLayerSource,
  localAreaTilesUrl,
  getCensusData,
} from "./mapUtils";
import { toast } from "react-toastify";

function getPopupData(zipcode, censusData, dataFilter) {
  if (!zipcode) return null;
  if (!censusData) return null;
  if (!dataFilter || !dataFilter.dataPoint || !dataFilter.year) return null;
  if (!censusData[zipcode]) return null;
  if (!censusData[zipcode][dataFilter.dataPoint]) return null;

  const dataPointYears = censusData[zipcode][dataFilter.dataPoint];
  const dataPointWithYear = censusData[zipcode][dataFilter.dataPoint].find(
    (d) => d.year == dataFilter.year
  );

  return {
    value: dataPointWithYear?.value || undefined,
    yearRange: `${dataPointYears[0].year} - ${
      dataPointYears[dataPointYears.length - 1].year
    }`,
    currentYear: dataFilter.year,
  };
}

// const rangeColors = ["#0000FF", "#9397FA", "#FFDED7", "#FF8174", "#FF0000"];
// const rangeColors = ["#003366", "#336699", "#F0F0F0", "#FF6666", "#990000"];
const rangeColors = ["#79d279", "#b7e0b7", "#478cd1", "#e09951", "#d47211"];

function updateRegionFill(map, censusData, dataFilter) {
  const { minVal, maxVal } = dataFilter;

  // Calculate the 5 equal parts (ranges) between minVal and maxVal
  const range1 = minVal + (maxVal - minVal) * 0.2; // First 20%
  const range2 = minVal + (maxVal - minVal) * 0.4; // 40%
  const range3 = minVal + (maxVal - minVal) * 0.6; // 60%
  const range4 = minVal + (maxVal - minVal) * 0.8; // 80%

  // Color mapping based on ranges
  const getColorForValue = (value) => {
    if (value <= range1) return rangeColors[0]; // Dark Blue for values in the first 20%
    if (value <= range2) return rangeColors[1]; // Light Blue for values in the next 20%
    if (value <= range3) return rangeColors[2]; // White for values in the middle 20%
    if (value <= range4) return rangeColors[3]; // Light Red for values in the next 20%
    if (value <= maxVal) return rangeColors[4]; // Dark Red for values in the top 20%
    return "#CCCCCC"; // Default color if no value matches
  };

  map.setPaintProperty("local-areas-fill", "fill-color", [
    "case",
    // For each condition, check if a feature's property matches and assign a color
    ...Object.keys(censusData).flatMap((zipcode) => {
      const data = censusData[zipcode];
      const value = data[dataFilter.dataPoint]?.find(
        (d) => d.year === dataFilter.year
      )?.value;

      // Return the color for the region based on its value
      return [
        ["==", ["get", "ZCTA5CE10"], zipcode], // Check if this is the current zipcode
        getColorForValue(value), // Set color based on value
      ];
    }),
    "#CCCCCC", // Default color if no data is available
  ]);
}

export default function MapBox({
  dataFilter,
  focusedRegion,
  onRegionClick,
  disableMoveToClickedArea = false,
}) {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const mapMarkerRef = useRef(null); // Ref to store the marker object
  const animationFrameRef = useRef(null); // Ref to store the animation frame object for the marker movement

  const areasCensusDataRef = useRef({}); // Ref to store the latest areasCensusData
  const dataFilterRef = useRef(dataFilter); // This ref is important, it updates maps, and popup data on filter change. Normal state doesn't seem to work like this.

  async function handleVisibleZipCodesChange(zipcodes) {
    // Filter zipcodes that don't already have census data, hence only new zipcodes that need to be loaded
    const newZipcodes = zipcodes.filter(
      (z) =>
        !areasCensusDataRef.current[z] ||
        !areasCensusDataRef.current[z][dataFilter.dataPoint]
    );
    const thisDataFilter = dataFilterRef.current;
    // console.log(`[handleVisibleZipCodesChange] ${newZipcodes.length} new Zipcodes, and ${zipcodes.length} total Zipcodes`);
    if (newZipcodes.length > 0) {
      const censusRes = await getCensusData(newZipcodes, dataFilterRef.current);

      if (!censusRes.success) return console.log("Error: ", censusRes.message);
      const censusData = censusRes.data;

      // console.log(`[handleVisibleCodeChanges] Loaded Data for ${censusData.length} Zipcodes`);

      // Update the areasCensusDataRef
      for (const item of censusData) {
        if (
          !areasCensusDataRef.current[item.zipcode] ||
          !areasCensusDataRef.current[item.zipcode][thisDataFilter.dataPoint]
        ) {
          areasCensusDataRef.current[item.zipcode] = {
            ...(areasCensusDataRef.current[item.zipcode] || {}),
            [thisDataFilter.dataPoint]: item[thisDataFilter.dataPoint],
          };
        }
      }
    }

    // Update the map
    updateRegionFill(
      mapRef.current,
      areasCensusDataRef.current,
      thisDataFilter
    );
  }

  useEffect(() => {
    dataFilterRef.current = dataFilter; // Update ref whenever dataFilter changes
    if (mapRef.current)
      handleVisibleZipCodesChange(getVisibleZipCodes(mapRef.current));
  }, [dataFilter]);

  useEffect(() => {
    if (mapRef.current) return; // Initialize map only once
    console.log("Initializing Map");

    mapRef.current = new maplibregl.Map({
      container: mapContainer.current, // Container ID
      // style: "https://tiles.stadiamaps.com/styles/alidade_smooth.json", // a little more detailed than cartocdn
      style: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
      // style: osmStyleConfig,
      center: [-72.677, 41.603], // Center the map on Connecticut [lng, lat]
      zoom: 10, // Adjust zoom level as needed
    });

    const map = mapRef.current;

    map.on("load", () => {
      map.getCanvas().style.cursor = "default";

      addLocalAreasToMap(map);

      let hoveredLocalAreaId = null;
      let popup = null;

      map.on("mousemove", "local-areas-fill", (e) => {
        let feature;
        if (e.features.length > 0) {
          feature = e.features[0];

          if (hoveredLocalAreaId !== null) {
            map.setFeatureState(
              {
                sourceLayer: localAreaLayerSource,
                source: "local-areas",
                id: hoveredLocalAreaId,
              },
              { hover: false }
            );
          }

          hoveredLocalAreaId = feature.id;
          map.setFeatureState(
            {
              sourceLayer: localAreaLayerSource,
              source: "local-areas",
              id: feature.properties["ZCTA5CE10"],
            },
            { hover: true }
          );
        }

        // If there's an existing popup, remove it
        if (popup) {
          popup.remove();
        }

        let popupData = getPopupData(
          feature?.properties["ZCTA5CE10"],
          areasCensusDataRef.current,
          dataFilterRef.current
        );
        popup = new maplibregl.Popup({ closeButton: false, offset: [0, -15] })
          .setLngLat(e.lngLat)
          .setHTML(
            `
            <div>
              <p class="text-sm font-semibold text-gray-700">Zip Code: ${feature?.properties["ZCTA5CE10"]}</p>
              <!--<p class="">Year Range: ${
                popupData?.yearRange || "N/A"
              }</p>-->
              <!--<p class="">Selected Year: ${popupData?.currentYear || "N/A"}</p>-->
              <p class="text-sm font-semibold text-gray-700">${dataFilterRef.current.label}: ${
              popupData?.value || "N/A"
            }</p>
            </div>
            `
          )
          .addTo(map);

        /*
        console.log(
          `[Region] ${feature?.properties["ZCTA5CE10"]}, ${feature?.properties["INTPTLAT10"]}, ${feature?.properties["INTPTLON10"]}`
        );
        */
      });

      map.on("mouseleave", "local-areas-fill", (e) => {
        if (hoveredLocalAreaId !== null) {
          map.setFeatureState(
            {
              sourceLayer: localAreaLayerSource,
              source: "local-areas",
              id: hoveredLocalAreaId,
            },
            { hover: false }
          );
        }

        // Remove popup
        if (popup) {
          popup.remove();
          popup = null;
        }

        hoveredLocalAreaId = null;
      });

      // Once the map is fully loaded with all layers
      map.once("idle", () => {
        const visibleZipCodes = getVisibleZipCodes(map);
        handleVisibleZipCodesChange(visibleZipCodes);
      });

      // Triggers everytime the user stops after moving
      map.on("moveend", () => {
        const visibleZipCodes = getVisibleZipCodes(map);
        handleVisibleZipCodesChange(visibleZipCodes);
      });

      // Handle Clicking on Filled Regions by Zipcode
      map.on("click", "local-areas-fill", (e) => {
        const feature = e.features[0];
        const region = {
          zipcode: feature.properties["ZCTA5CE10"],
          lat: parseFloat(feature.properties["INTPTLAT10"]),
          lng: parseFloat(feature.properties["INTPTLON10"]),
        };
        onRegionClick?.(region);
        // This region click is enough if you setup the focus region, since a useEffect is observing it, and would move the map and the marker
        // mapMarkerRef.current.setLngLat([region.lng, region.lat]);
        if (!disableMoveToClickedArea) {
          map.flyTo({
            center: [region.lng, region.lat],
            zoom: 10,
          });
        }
      });
    });

    mapMarkerRef.current = new maplibregl.Marker();
    mapMarkerRef.current.setLngLat([-72.677, 41.603]);
    mapMarkerRef.current.addTo(map);
    /*
    mapMarkerRef.current.setPopup(
      new maplibregl.Popup({ offset: [0, -25] }).setHTML(
        "<h1>Connecticut</h1>"
      )
    );
    */

    map.addControl(new maplibregl.NavigationControl());

    // Cleanup
    /*
    return () => {
      map.remove(); // Don't, It introduces errors and bugs
    };
    */
  }, []);

  const moveMarker = (from, to, duration = 2000) => {
    const start = performance.now();

    const animate = (timestamp) => {
      const elapsed = timestamp - start;
      const t = Math.min(elapsed / duration, 1); // Normalize time (0 to 1)

      // Interpolate longitude and latitude
      const lng = from[0] + (to[0] - from[0]) * t;
      const lat = from[1] + (to[1] - from[1]) * t;

      // Update marker position
      mapMarkerRef.current.setLngLat([lng, lat]);

      if (t < 1) {
        animationFrameRef.current = requestAnimationFrame(animate); // Continue animation
      }
    };

    animationFrameRef.current = requestAnimationFrame(animate); // Start animation
  };

  const handleMoveMarker = (to, duration = 2000) => {
    const from = mapMarkerRef.current.getLngLat().toArray(); // Get current marker position
    // const to = [-74.006, 40.7128]; // Target position (example: New York City)
    moveMarker(from, to, duration);
  };

  useEffect(() => {
    if (
      focusedRegion &&
      focusedRegion.lat &&
      focusedRegion.lng &&
      mapRef.current
    ) {
      const map = mapRef.current;
      map.flyTo({
        center: [focusedRegion.lng, focusedRegion.lat],
        zoom: 10,
      });
      handleMoveMarker([focusedRegion.lng, focusedRegion.lat], 500);
      /*
      const marker = mapMarkerRef.current;
      if (marker) {
        marker.setLngLat([focusedRegion.lng, focusedRegion.lat]);
      }
      */
    }
  }, [focusedRegion]);

  return (
    <div className="w-full h-full relative overflow-hidden">
      {/* Legend for Data */}
      <div className="absolute bottom-10 right-4 z-20 w-fit">
        <div className="bg-white/90 backdrop-blur-sm rounded-lg shadow-lg p-3 w-[200px]">
          <div className="text-sm font-medium mb-2">Data Distribution</div>
          <div className="flex items-center gap-0.5">
            {rangeColors.map((c, idx) => (
              <div
                className="h-4 w-full"
                style={{ backgroundColor: c, opacity: 0.8 }}
              />
            ))}
          </div>
          <div className="flex justify-between mt-1 text-xs text-gray-600">
            <span>{Intl.NumberFormat().format(dataFilter?.minVal)}</span>
            <span>{Intl.NumberFormat().format(dataFilter?.maxVal)}</span>
          </div>
        </div>
      </div>
      <div
        ref={mapContainer}
        style={{ width: "100%", height: "100%", padding: "200px" }} // Ensure container has dimensions
        id="map"
      />
    </div>
  );
}
