export const dataItems = [
  {
    label: "Population",
    startYear: 2010,
    endYear: 2021,
    value: "populationData",
    icon: "👨‍👩‍👧‍👦",
    description: `Areas with increasing population often signal a higher demand for housing. Investing in areas where people are moving to, rather than from, could offer more security for your investment.`,
  },
  {
    label: "Employment",
    value: "employmentData",
    startYear: 2011,
    endYear: 2020,
    icon: "👨‍👩‍👧‍👦",
    description: `Areas with higher employment rates might indicate a stable economy, which can be attractive to potential tenants.`,
  },
  {
    label: "Median Gross Rent",
    value: "medianGrossRent",
    startYear: 2011,
    endYear: 2020,
    icon: "👨‍👩‍👧‍👦",
    description: `Investors can assess whether the median gross rent aligns with their investment goals and yields. It aids landlords in making informed decisions regarding rent adjustments during lease renewals. A rising median gross rent may indicate increasing demand.`,
  },
  {
    label: "Median Income",
    value: "medianIncome",
    startYear: 2011,
    endYear: 2020,
    icon: "👨‍👩‍👧‍👦",
    description: `Areas with higher median incomes might suggest greater financial stability of tenants or buyers, potentially reducing investment risks. Rising median income might indicate areas experiencing economic growth, potentially offering lucrative investment opportunities.`,
  },
];
export const years = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
];

export function getDataItemByValue(value) {
  return dataItems.find((item) => item.value === value);
}
