"use client";

import { useState, useContext } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Lock } from "lucide-react";
import { UserContext } from "@/context/UserContext";
import { Link } from "react-router-dom";

export default function UpgradeCard({ isLoggedIn }) {

  return (
    <Card className="w-full max-w-md bg-white shadow-lg">
      <CardHeader className="text-center">
        <div className="mx-auto bg-gray-100 rounded-full p-3 w-12 h-12 flex items-center justify-center mb-4">
          <Lock className="w-6 h-6 text-gray-600" />
        </div>
        <CardTitle className="text-2xl font-semibold text-gray-800">
          {isLoggedIn ? "Upgrade to Premium" : "Premium Feature"}
        </CardTitle>
        <CardDescription className="text-gray-600 mt-2">
          {isLoggedIn
            ? "Please upgrade to access this premium feature."
            : "Please log in and upgrade to access this premium feature."}
        </CardDescription>
      </CardHeader>
      <CardContent className="text-center">
        <p className="text-sm text-gray-500">
          {isLoggedIn
            ? "Premium features await. Upgrade to explore the full potential"
            : "Premium features await. Log in and upgrade to explore the full potential."}
        </p>
      </CardContent>
      <CardFooter className="flex justify-center">
        <Link to={isLoggedIn ? "/subscription" : "/login"}>
          <Button
            className="bg-[#556BD6] hover:bg-[#556BD6]/90 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out"
          >
            {isLoggedIn ? "Upgrade Now" : "Log In"}
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
}
